<template>
  <div style="width:100%;height:1000px;background:#F9F9F9">
    <div class="container">
      <img @click="back" class="back" src="../assets/images/back.png" alt="" />
      <p class="mymsg">我的订单</p>
    </div>
    <div class="list">
      <h3>挂号订单</h3>
      <p>问诊号:17</p>
      <p>挂号科室:外科</p>
      <p>挂号费:$17</p>
      <p>挂号时间:2020-10-13 09:28:30</p>
      <button class="btn" @click="btnDel">删除订单</button>
    </div>
    <div class="list" style="margin-top:68%">
      <div style="display:flex;justify-content:space-between">
        <h3>图文问诊 $35</h3>
        <p>2020-10-13</p>
      </div>
      <p>这是条图文问诊具体内容</p>

      <button class="btn" @click="btnDel">删除订单</button>
      <button class="btn" @click="btnSel">再次咨询</button>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {}
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    btnDel() {
      Dialog.confirm({
        message: '你是否确认删除订单?'
      })
        .then(() => {
          // on confirm
        })
        .catch(() => {
          // on cancel
        })
    },
    btnSel() {
      Dialog.confirm({
        message: '再次咨询'
      })
        .then(() => {
          // on confirm
        })
        .catch(() => {
          // on cancel
        })
    }
  }
}
</script>

<style scoped>
.list {
  position: absolute;
  top: 55px;
  background: #ffffff;
  width: 90%;
  margin: 10px;
  padding: 10px;
  border-radius: 6px;
}
.list h3 {
  border-left: 5px solid green;
  line-height: 15px;
  padding-left: 10px;
}
p {
  color: #666666;
}
.btn {
  width: 100px;
  height: 36px;
  border-radius: 17px;
  border: 1px solid #d8d8d8;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /* line-height: 17px; */
  float: right;
  margin: 5px;
}
.back {
  z-index: 100;
}
</style>
